import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import service from '../request/index'
import {commitprojectinfocd} from "@/request/api";
const routes: Array<RouteRecordRaw> = [
    {
        path: '/main',
        alias: '/',
        name: 'home',
        meta:{title:'mes'},
        component: HomeView,
        children: [
            {
                path: '/goods', name: 'Goods',
                meta: {
                    IsShow: true,
                    title: '客户资料维护'
                },
                component: () => import('../views/GoodsView.vue')
            },
            {
                path: '/userlist',
                name: 'UserList',
                meta: {
                    IsShow: true,
                    title: '用户列表',
                },
                component: () => import('../views/User/UserList.vue')
            },
            {
                path: '/bindingperson', name: 'bindingperson',
                component: () => import ('../views/BindingPerson.vue')
            },

            { path:'/getprojectinfo', name: 'GetProjectInfoZC',
                component:()=>import ('@/views/GetProjectInfoZC.vue')
            },

            { path:'/getprojectinfocd', name: 'getprojectinfocd',
                component:()=>import ('@/views/GetProjectInfoCD.vue')
            },

            { path:'/getwp_no', name: 'getwp_no',
                component:()=>import ('@/views/GetWp_Query.vue')
            },
            {
                path:'/data_analysis/orderinfo',name:'orderinfo',
                component:()=>import ('@/views/OrderInfoView.vue')
            },
        ]
    },
    {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutView.vue')
  },
    {
        path:'/scan',
        name:'scan',
        component: () => import(/* webpackChunkName: "about" */ '../views/ScanView.vue')
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach ((to, from, next) => {
  const token = localStorage.getItem('token')

  const pageTitle = (to.meta.title as string)||''
  document.title = pageTitle


  if (to.path=='/bindingperson' || to.path=='/scan')
  {
    next()
    return
  }
  if (!token) {
    next('/bindingperson')
    return
  }
  try {
    console.log('check')
     service({
          url:"/checktoken",
          method:"post",
          data:{'userid':'abc','token':token},
          }
          ).then((res)=>{
              console.log(res.data)
              if (res.data.suc==1)
              {
                next()
              }
              else{
                    next('/bindingperson')
                    return
              }
            })
  }
  catch(error){
      next('/bindingperson')
}
})
export default router

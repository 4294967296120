<template>
  <div>
    <h6>{{scanText}}</h6>
    <button @click="redirectToUrl">跳转到指定网站</button>
  </div>
  <br>
  <div class="page-scan">
    <video ref="video" id="video" class="scan-video" autoplay></video>
    <div v-show="msgShow" class="scan-msg">{{ message}}</div>
  </div>
</template>
<script>
import { BrowserMultiFormatReader } from "@zxing/library";
export default {
  name: "scanRegion",
  data() {
    return {
      codeReader: null,
      scanText: "",
      message: "正在扫描",
      msgShow: false,
    };
  },

  mounted() {
    this.codeReader = new BrowserMultiFormatReader();
    this.openScan();

  },
  // 记得销毁，要不然返回再进去会不显示
  unmounted() {
    this.codeReader.reset();
  },

  methods: {

    openScan() {
      this.codeReader.getVideoInputDevices().then((videoInputDevices) => {
        this.tipShow = true;
        this.tipMsg = "正在调用摄像头...";
        // 因为获取的摄像头有可能是前置有可能是后置，但是一般最后一个会是后置，所以在这做一下处理
        // 默认获取第一个摄像头设备id
        let firstDeviceId = videoInputDevices[0].deviceId;
        if (videoInputDevices.length > 1) {
          // 获取后置摄像头
          let deviceLength = videoInputDevices.length;
          --deviceLength;
          firstDeviceId = videoInputDevices[deviceLength].deviceId;
        }
        this.decodeFromInputVideoFunc(firstDeviceId);
        // window.location.href =this.scanText
      }).catch((err) => {
          this.tipShow = false;
          console.error(err);
        });
    },

    decodeFromInputVideoFunc(firstDeviceId) {
      this.codeReader.reset(); // 重置
      this.scanText = "";
      this.codeReader.decodeFromInputVideoDeviceContinuously(firstDeviceId,"video",(result, err) => {
        this.tipMsg = "正在尝试识别...";
        this.scanText = "";
        if (result) {
          // 获取到的是二维码内容，然后在这个if里面写业务逻辑即可
          this.scanText = result.text;
          if (this.scanText) {
            this.tipShow = false;
            this.codeReader.reset();
            let params = {
              scId: this.$route.query.scId,
              qrcode: this.scanText
            }
            window.location.href =this.scanText
          }
        }
        if (err && !err) {
          this.message= "识别失败";
          setTimeout(() => {
            this.msgShow = false;
          }, 200);
          console.error(err);
        }

        // window.open(this.scanText,'_bank')
        // window.location.href =  this.scanText
      }
      );
    },
      redirectToUrl(){
        window.location.href =this.scanText
      }    ,
  },
};
</script>

<style lang="scss" scoped>
.scan-video {
  width: 100%;
  height: 80vh;
}
.scan-msg {
  width: 100vw;
  text-align: center;
  margin-bottom: 10vh;
  color: white;
  font-size: 5vw;
}
.page-scan {
  width: 100vw;
  background-color: #363636;
}
</style>
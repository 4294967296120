import axios from "axios";
const  service=axios.create({
    baseURL:'/api',
    timeout:8000,
    headers:{
        "Content-Type":"application/json;charset=utf-8",
        // "Access-Control-Allow-Origin": "http://localhost:8080",
        // "Access-Control-Allow-Headers": "Content-Type, Authorization"
    }
})
//请求拦截
service.interceptors.request.use((config)=>{
    config.headers=config.headers || {}
    if(localStorage.getItem('token')){
        config.headers.token=localStorage.getItem('token') || ""
    }
    return config
})
// 响应拦截
service.interceptors.response.use((res)=>{
    const code:number=res.status
    if(code!=200){
        return Promise.reject(res)
    }
    return res
},(err)=>{
    console.log(err);
})
export default service